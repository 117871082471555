

































































import "reflect-metadata"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import MultipassPlaceMap from "@/components/multipass/MultipassPlaceMap.vue"
import MultipassZoneCard from "@/components/multipass/MultipassZoneCard.vue"
import MultipassCart from "@/components/multipass/MultipassCart.vue"
import _ from "lodash"
import filters from "@/filters"

@Component({
  components: {
    MultipassCart,
    MultipassPlaceMap,
    MultipassZoneCard,
  },
  filters: filters,
})
export default class MultipassSession extends Vue {
  isLoading = true
  obj: any = null
  placeMapData: Dictionary<any>[] = []
  cartSessionZones = []
  cartKey: string | null = null
  @Prop() multipassConfig!: any
  purchasableZones: any[] = []

  mounted() {
    const multipassConfigLookupRef = this.$route.params.lookupRef
    this.cartKey = `${multipassConfigLookupRef}-${this.$route.params?.sessionId}`
    this.$store.commit("multipassCart/loadState", this.cartKey)
    this.getMultipassSession()
  }

  getMultipassSession() {
    this.$api.getMultipassSession(this.$route.params?.sessionId).then(
      (response: any) => {
        this.obj = response
        this.cartSessionZones = this.obj.session.session_zones
        this.buildMap()
        this.isLoading = false

        this.purchasableZones = this.obj.session.session_zones.filter(
          (z: any) => z.is_purchasable
        )
      },
      () => {}
    )
  }

  get hasMap() {
    if (!this.obj || !this.obj.id) {
      return false
    }
    return _.some(this.obj.session.session_zones, (zone) => _.size(zone.svg))
  }

  goToZone(id: string) {
    this.$router.push({
      name: "multipass-zone",
      params: { zoneId: id, lookupRef: this.multipassConfig.lookup_ref },
      query: this.$defaultQuery(),
    })
  }

  buildMap() {
    this.placeMapData = this.obj.session.session_zones.filter((z: any) => z.layout)
  }

  onSessionChange(sessionId: string) {
    if (!sessionId || sessionId == this.$route.params.sessionId) {
      return
    }

    this.$router.push({
      name: "multipass-session",
      params: {
        lookupRef: this.multipassConfig.lookup_ref,
        sessionId: sessionId,
      },
      query: this.$defaultQuery(),
    })
  }

  @Watch("$route.params.sessionId")
  onRouterParamSessionChange() {
    this.getMultipassSession()
  }
}
