





































































import _ from "lodash"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"
import ConfirmSeatDialog from "@/components/ConfirmSeatDialog.vue"

interface MultipassConcession {
  id: string
  name: string
  description: string
}

@Component({
  components: { ConfirmSeatDialog },
  filters: filters,
})
export default class MultipassPreflightTicketCard extends Vue {
  @Prop() multipassConfig!: any
  @Prop() zone!: any
  @Prop() line!: any
  @Prop() is_readonly!: boolean

  @Prop() selectedMultipassConcessionId!: number
  @Prop() cartIndex!: number
  @Prop() cartKey!: any

  // SeatNoticeModal
  seatWithNotice: any = null

  multipassConcessionId: number | null = null
  initialized: boolean = false

  mounted() {
    this.multipassConcessionId = this.selectedMultipassConcessionId
    this.initialized = true
  }

  get selectedMultipassConcession() {
    return _.find(this.getZoneDropdownConcessions(), { id: this.multipassConcessionId })
  }

  getZoneDropdownConcessions(): MultipassConcession[] {
    const key = this.zone.name || ""
    const map = this.multipassConfig?.zones_concessions_map || {}
    return map[key] || []
  }

  removeSeat() {
    this.$store.dispatch("multipassCart/update", [
      "removeLine",
      this.cartIndex,
      this.cartKey,
    ])
  }

  onMultipassConcessionIdChange(multipassConcessionId: number) {
    if (!this.initialized) return
    this.$store.dispatch("multipassCart/update", [
      "setMultipassConcessionId",
      {
        index: this.cartIndex,
        multipassConcessionId: multipassConcessionId,
      },
      this.cartKey,
    ])
  }
  @Watch("selectedMultipassConcessionId")
  onselectedMultipassConcessionId() {
    this.multipassConcessionId = this.selectedMultipassConcessionId
  }
}
