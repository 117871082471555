





















































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

@Component({
  filters: filters,
})
export default class ZoneCard extends Vue {
  @Prop() zone!: any
  visible: boolean = false

  ticketsType() {
    if (!this.zone.is_purchasable) return
    this.$emit("zoneClick", this.zone.id)
  }
  openInfo() {
    this.visible = true
  }
  closeInfo() {
    this.visible = false
  }
}
