


























































































































import _ from "lodash"
import "reflect-metadata"
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { mapState } from "vuex"
import filters from "@/filters"
import MultipassPreflightTicketCard from "@/components/multipass/MultipassPreflightTicketCard.vue"

@Component({
  components: { MultipassPreflightTicketCard },
  computed: {
    ...mapState({
      cart: (state: any) => state.multipassCart.cart,
    }),
  },
  filters: filters,
})
export default class MultipassCart extends Vue {
  @Prop({ default: null }) multipassConfig!: any
  @Prop({ default: [] }) cartSessionZones!: any
  @Prop({ default: null }) multipassSession!: any
  @Prop() cartKey!: any

  expanded: boolean = false
  confirming: boolean = false

  preflightOrder: any = null
  preflightLoading = false

  get totalAmountAsCurrency(): string {
    if (
      this.$options.filters &&
      this.preflightOrder &&
      this.preflightOrder.total_ticket_price
    ) {
      return this.$options.filters.currency(this.preflightOrder.total_ticket_price)
    }

    return ""
  }

  get numTickets() {
    if (!this.preflightOrder || !this.preflightOrder.num_tickets) {
      return 0
    }

    return this.preflightOrder.num_tickets
  }

  mounted() {
    this.expanded = this.$store.state.multipassCart.visible
    this.getPreflightOrder()
  }

  confirm(reservation: boolean) {
    if (!this.multipassSession || this.confirming) {
      return
    }

    if (this.numTickets == 0) {
      return
    }

    if (!this.isPurchasable) {
      this.$store.dispatch("multipassCart/update", ["toggle", true])
      return
    }

    this.confirming = true
    this.preflightLoading = true
    const protectionToken = "p" in this.$route.query ? this.$route.query.p : null

    this.$api
      .createMultipassOrder({
        multipass_config_lookup_ref: this.multipassConfig.lookup_ref,
        multipass_session_id: this.multipassSession.id,
        lines: this.getCartLines(),
        protection_token: protectionToken,
        reservation: reservation,
      })
      .then(
        (response: any) => {
          const data: { [k: string]: any } = {}
          data[this.$route.params.event_lookup_ref] = response.lookup_ref
          this.$store.commit("order/update", data)
          this.$store.dispatch("multipassCart/update", [
            "resetCart",
            undefined,
            this.cartKey,
          ])

          if (reservation) {
            this.$router.push({
              name: "multipass-order-reserved",
              params: {
                lookup_ref: response.lookup_ref,
              },
              query: this.$defaultQuery(),
            })
          } else {
            this.$router.push({
              name: "multipass-order-confirm",
              params: {
                lookup_ref: response.lookup_ref,
              },
              query: this.$defaultQuery(),
            })
          }
        },
        (error: any) => {
          if (error.response.status == 400) {
            this.$store.dispatch("ui/error", error.response.data.message)
          }
          this.confirming = false
          this.preflightLoading = false

          // Call preflight to check if some tickets are not available anymore
          // And reload map in that case
          this.getPreflightOrder()
        }
      )
  }

  get isPurchasable() {
    if (this.numTickets == 0) {
      return false
    }

    if (!this.preflightOrder) {
      return false
    }

    return this.preflightOrder.is_purchasable
  }

  get isReservable() {
    if (!this.preflightOrder) {
      return false
    }
    return this.preflightOrder.is_reservable
  }

  getCartLines(): Array<{
    sessionzone_id: number
    multipass_concession_id: number | null
    seat_id: number
    multipass_session_id: number
  }> {
    return this.$store.state.multipassCart.cart.map((line: any) => {
      return {
        sessionzone_id: line.sessionZoneId,
        multipass_concession_id: line.multipassConcessionId,
        seat_id: line.seat.id,
        multipass_session_id: line.multipassSessionId,
      }
    })
  }

  getPreflightOrder() {
    this.preflightLoading = true

    this.$api
      .multipassPreflightOrder({
        multipass_config_lookup_ref: this.multipassConfig.lookup_ref,
        multipass_session_id: this.multipassSession.id,
        lines: this.getCartLines(),
      })
      .then(
        (response: any) => {
          this.preflightLoading = false
          this.preflightOrder = response

          this.$store.dispatch("multipassCart/update", [
            "setNumTickets",
            response.num_tickets,
            this.cartKey,
          ])

          // Check if there are any invalid seats and remove them from the cart
          if (response.invalid_seats.length > 0) {
            this.$store.dispatch(
              "ui/error",
              "Algunas de las butacas seleccionadas ya no están disponibles, se actualizará tu selección."
            )
            this.$store.dispatch("multipassCart/invalidSeatsDetected")
            this.$store.dispatch("multipassCart/update", ["toggle", true])
          }
          response.invalid_seats.forEach((seat_id: number) => {
            this.$store.dispatch("multipassCart/update", ["removeSeat", seat_id])
          })
        },
        (error: any) => {
          this.preflightLoading = false
          this.$store.dispatch("multipassCart/update", ["resetCart"])
          this.$store.dispatch("ui/error", error.response.data.message)
        }
      )
  }

  debouncedGetPreflightOrder = _.debounce(this.getPreflightOrder, 700, {
    maxWait: 3000,
  })

  selectMultipassSession(multipassSessionId: string) {
    this.$router.push({
      name: "multipass-session",
      params: {
        lookupRef: this.multipassConfig.lookup_ref,
        sessionId: multipassSessionId,
      },
      query: this.$defaultQuery(),
    })

    if (this.expanded) {
      this.$store.dispatch("multipassCart/update", ["toggle", false])
    }
  }

  @Watch("$store.state.multipassCart.cart", { deep: true })
  onCartChange() {
    this.preflightLoading = true
    this.debouncedGetPreflightOrder()
  }

  @Watch("$store.state.multipassCart.visible")
  onCartVisibleChange(value: boolean) {
    if (value != this.expanded) {
      this.expanded = value
    }
  }

  @Watch("expanded")
  onExpandedChange(value: boolean) {
    this.$store.dispatch("multipassCart/update", ["toggle", value])
  }
}
